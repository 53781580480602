import { Link as RouterLink } from 'react-router-dom'
import { styled } from '@mui/system'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import HearingAgencyLogo from '../assets/HearingAgencyLogo'

const Link = styled(RouterLink)({
  color: 'white',
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline'
  },
  '&:visited': {
    color: 'white'
  }
})

const Footer = () => {
  return (
    <Stack
      className='footer'
      alignItems='center'
      spacing={2}
      sx={{
        padding: '2rem 0',
        backgroundColor: 'rgb(14,40,100)',
        color: 'white'
      }}
    >
      <Box sx={{ marginBottom: { xs: '0.5rem', sm: '0' } }}>
        <HearingAgencyLogo style={{ width: '175px' }} />
      </Box>
      <Typography variant='subtitle2' sx={{ textAlign: 'center' }}>
        Our business hours are weekdays 8 AM to 7 PM EST and Saturdays 10 AM to
        5 PM EST.
      </Typography>
      <Stack direction='row' spacing={2}>
        <Link to='/terms'>
          <Typography variant='subtitle2'>Terms</Typography>
        </Link>
        <Typography variant='subtitle2'>|</Typography>
        <Link to='/privacy'>
          <Typography variant='subtitle2'>Privacy</Typography>
        </Link>
        <Typography variant='subtitle2'>|</Typography>
        <Link to='/bill-of-sale'>
          <Typography variant='subtitle2'>Bill of Sale</Typography>
        </Link>
        <Typography variant='subtitle2'>|</Typography>
        <Typography variant='subtitle2'>Privacy Request</Typography>
      </Stack>
      <Typography variant='subtitle2'>
        © 2021 Home Hearing, Inc., all rights reserved.
      </Typography>
    </Stack>
  )
}

export default Footer
