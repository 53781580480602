const VideoComponent = () => {
  const vimeoId = '856995482'
  return (
    <iframe
      title='Hearing Agency Intro Video - Hosted on Vimeo'
      src={`https://player.vimeo.com/video/${vimeoId}?autoplay=1&muted=1&loop=1&badge=0&autopause=0&player_id=0&app_id=58479`}
      allow='autoplay; fullscreen'
      style={{
        top: 0,
        position: 'absolute',
        width: '100%',
        height: '100%',
        border: '0',
        padding: '0px'
      }}
    ></iframe>
  )
}

export default VideoComponent
