import React from 'react'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import Container from '@mui/material/Container'

const Terms = () => {
  return (
    <Container maxWidth='lg' sx={{ padding: '5rem 0' }}>
      <Stack spacing={2}>
        <Typography variant='h5'>Terms & Conditions</Typography>
        <Typography variant='h6'>Welcome!</Typography>
        <Typography variant='body2'>
          We’re delighted you’ve decided to use our services including, but not
          limited to, our technologies or functions offered on our website and
          all related sites, applications, customer service team, hearing
          specialists, and third-party vendors, all of which we refer to simply
          as the “Services.”
        </Typography>
        <Typography variant='body2'>
          Yes Hearing may be referred to as “Yes”, “YH”, “we”, “us, “our”, or
          “The Company” and the following terms of service govern the use if
          this website. “Customer”, “You” and “Your” refers to you, the person
          using this website, and by doing so, accepts our Terms of Service.
          Please therefore read the Terms of Service carefully. The terms of
          service may change, and the new terms will become effective as of the
          date of publishing. These terms are binding on you, and should be read
          whenever you use the site.
        </Typography>

        <Typography variant='h6'>
          We do not offer professional advice
        </Typography>
        <Typography variant='body2'>
          Any and all information supplied by this website, or any of its
          employees or independent contractors, in any format, is for
          informational purposes or general guidance, and is neither medical or
          other professional advice. It is important that you do not make
          medical decisions without first speaking to a healthcare professional.
          The receipt and return of any questions or feedback you submit to us
          does not create a professional relationship.
        </Typography>

        <Typography variant='h6'>Permitted Use</Typography>
        <Typography variant='body2'>
          The site is not intended for use by anyone under 18 years of age. We
          do not sell products for purchase by children. All users may not use
          the site for anything but personal purposes. You may not use the site
          for anything unlawful or prohibited by these terms of service. We
          reserve the right to refuse service, discontinue transactions or
          cancel orders at our sole discretion.
        </Typography>

        <Typography variant='h6'>Changes to the Terms of Service</Typography>
        <Typography variant='body2'>
          We may modify our terms of service from time to time, and we strongly
          recommend that you periodically review our terms of service. You will
          be able to tell when changes have been made by checking the "Last
          Updated" information that appears at the end of the terms of service.
          Your continued use of the site will be deemed as irrevocable
          acceptance of any revisions.
        </Typography>

        <Typography variant='h6'>Law and Jurisdiction</Typography>
        <Typography variant='body2'>
          These terms and conditions will be governed by and construed in
          accordance with the laws of New York, New York, and any disputes
          relating to these terms and conditions will be subject to the
          exclusive jurisdiction of the courts of New York, New York.
        </Typography>

        <Typography variant='body2'>
          Yes Hearing’s registered address is: 555 Madison Avenue, New York, NY
          10022, (844)-241-8475. You can contact Yes Hearing by email at
          contact@Yes Hearing.com
        </Typography>

        <Typography variant='h6'>Limitations</Typography>
        <Typography variant='body2'>
          None of these services can be guaranteed in their entirety. If you
          believe you have received dissatisfactory service you must notify Yes
          Hearing at: contact@Yes Hearing.com within 48 hours of your Home
          Visit.
        </Typography>

        <Typography variant='body2'>
          Yes Hearing retains the right to conduct a thorough inquiry into any
          claims held against the company or contractors.
        </Typography>

        <Typography variant='body2'>
          By accepting these Terms and Conditions, you also accept the Bill of
          Sale
        </Typography>
      </Stack>
    </Container>
  )
}

export default Terms
