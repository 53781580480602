import { initializeApp } from 'firebase/app'
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore'
import { getAuth, connectAuthEmulator } from 'firebase/auth'
import { getStorage, connectStorageEmulator } from 'firebase/storage'
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions'

const firebaseConfig = {
  apiKey: 'AIzaSyBPH610oFTJlWC0zabNkJx0W0E4cVHfUGo',
  authDomain: 'ys-private-web-apps.firebaseapp.com',
  projectId: 'ys-private-web-apps',
  storageBucket: 'ys-private-web-apps.appspot.com',
  messagingSenderId: '704014440101',
  appId: '1:704014440101:web:bfd43eb0fb15a81cc89f4c',
  measurementId: 'G-Q6114E0P7N'
}

const app = initializeApp(firebaseConfig)

let db = getFirestore(app)
let auth = getAuth(app)
let storage = getStorage(app)
let functions = getFunctions(app)

const isEmulator = window.location.hostname === 'localhost'

if (isEmulator) {
  connectFirestoreEmulator(db, 'localhost', 8080)
  connectAuthEmulator(auth, 'http://localhost:9099')
  connectStorageEmulator(storage, 'localhost', 9199)
  connectFunctionsEmulator(functions, 'localhost', 5001)
}

export { db, auth, storage, functions }
