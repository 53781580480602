import { useState } from 'react'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Form from './Form'
import SectionWrapper from '../../../components/SectionWrapper'

const PriceQuoteSection = () => {
  const [submitted, setSubmitted] = useState(false)

  return (
    <SectionWrapper
      sx={{
        py: { xs: '0', md: '30px' },
        alignItems: submitted ? 'center' : 'flex-start'
      }}
    >
      <>
        <Stack spacing={3}>
          <Typography
            variant='h4'
            color='rgb(14, 40, 100)'
            sx={{
              fontWeight: 700,
              fontFamily: 'Lora, sans-serif',
              fontSize: '2rem',
              lineHeight: '1.2'
            }}
          >
            Get your exclusive price quote and save 25-40% off
          </Typography>

          <Typography
            variant='body1'
            sx={{
              lineHeight: '1.25',
              fontSize: '1.25rem',
              letterSpacing: '0.00938em'
            }}
          >
            Complete this form and our certified hearing specialist will
            promptly contact you. Expect a call within minutes for personalized
            assistance.
          </Typography>
          <Typography
            variant='body1'
            sx={{
              lineHeight: '1.25',
              fontSize: '1.25rem',
              letterSpacing: '0.00938em'
            }}
          >
            We provide a comprehensive price quote that's all-inclusive. Rest
            assured, there are no hidden fees and absolutely no aggressive sales
            tactics.
          </Typography>
          <Typography
            variant='body1'
            sx={{
              lineHeight: '1.25',
              fontSize: '1.25rem',
              letterSpacing: '0.00938em'
            }}
          >
            In just a brief conversation of five minutes or less, you can
            uncover significant savings. Take advantage of 25-40% discounts on
            your next hearing aids with us.
          </Typography>
          <Typography
            variant='body1'
            sx={{
              lineHeight: '1.25',
              fontSize: '1.25rem',
              letterSpacing: '0.00938em'
            }}
          >
            Our business operates during convenient hours for your ease. Reach
            us on weekdays from 8 am to 6 pm and on Saturdays from 10 am to 5 pm
            Eastern Standard Time.
          </Typography>
        </Stack>
      </>
      <>
        <Form submitted={submitted} setSubmitted={setSubmitted} />
      </>
    </SectionWrapper>
  )
}

export default PriceQuoteSection
