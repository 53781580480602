import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import PhoneIcon from '@mui/icons-material/Phone'
import SectionWrapper from '../../components/SectionWrapper'

const ProductDetailsSection = ({ brandDetails, makeCall }) => {
  return (
    <SectionWrapper
      sx={{
        alignItems: 'center'
      }}
      direction='column-reverse'
      breakpoint='899'
    >
      <>
        <Typography
          variant='h4'
          color='rgb(14, 40, 100)'
          sx={{
            fontWeight: 700,
            fontFamily: 'Lora, sans-serif',
            fontSize: '2rem',
            lineHeight: '1.2',
            mb: '24px'
          }}
        >
          {`Save 25-40% off ${brandDetails.name} ${brandDetails.product} hearing aids`}
        </Typography>
        <Typography
          variant='body1'
          sx={{
            lineHeight: '1.25',
            fontSize: '1.25rem',
            letterSpacing: '0.00938em',
            mb: '48px'
          }}
        >
          {` ${brandDetails.product} is ${brandDetails.name}'s most advanced product line yet. Available
            options include rechargeable batteries, a water resistant exterior,
            and audio streaming. We'll make sure you save money, without
            compromising on quality - even on the latest, most cutting edge
            technology.`}
        </Typography>
        <Typography
          variant='body1'
          sx={{
            lineHeight: '1.25',
            fontSize: '1.25rem',
            letterSpacing: '0.00938em'
          }}
        >
          <strong style={{ color: 'rgb(14, 40, 100)' }}>
            Looking for a price?
          </strong>{' '}
          Our prices are so low we can’t publish them online. Talk with one of
          our licensed hearing experts to get a price.
        </Typography>
        <Button
          variant='contained'
          onClick={makeCall}
          startIcon={<PhoneIcon style={{ fontSize: '1.5rem' }} />}
          sx={{
            marginTop: '48px',
            height: '3.5rem',
            borderRadius: '8px',
            textTransform: 'none'
          }}
        >
          <Typography
            variant='body1'
            sx={{
              fontWeight: 600,
              fontSize: '24px',
              letterSpacing: '0.00938em',
              lineHeight: '1.25',
              whiteSpace: 'noWrap',
              '@media screen and (max-width:1099px)': {
                fontSize: '19.2px'
              }
            }}
          >
            Get a price quote
          </Typography>
        </Button>
      </>
      <>
        <Box sx={{ maxWidth: '500px', display: 'block', mx: 'auto' }}>
          <Box
            sx={{
              position: 'relative',
              paddingBottom: '100%'
            }}
          >
            <img
              src={brandDetails.img}
              alt='Support'
              style={{
                position: 'absolute',
                top: 0,
                width: '100%'
              }}
            />
          </Box>
        </Box>
      </>
    </SectionWrapper>
  )
}

export default ProductDetailsSection
