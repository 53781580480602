import { useContext, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { UserTaggingContext } from '../utilities/tagging'
import useScrollPosition from '../utilities/useScrollPosition'

import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'

import HearingAgencyLogo from '../assets/HearingAgencyLogo'

const Header = () => {
  const scrollPosition = useScrollPosition()
  const navigate = useNavigate()
  const sessionData = useContext(UserTaggingContext)
  const [assignedNumber, setAssignedNumber] = useState('877-220-6389')
  const [isLoading] = useState(false)

  useEffect(() => {
    if (sessionData.formattedPhoneNumber) {
      setAssignedNumber(sessionData.formattedPhoneNumber)
    }
  }, [sessionData])

  return (
    <Box
      sx={{
        position: { xs: 'relative', md: 'sticky' },
        top: 0,
        boxShadow: { xs: 'none', md: scrollPosition > 0 ? 3 : 'none' },
        transition: 'box-shadow 0.3s ease-in-out',
        height: '100%',
        background: '#FFFFFF',
        zIndex: 1000,
        padding: { xs: '2rem 0', sm: '1rem 0 1rem 0' }
      }}
    >
      <Container sx={{ maxWidth: '1320px !important' }}>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          justifyContent={{ xs: 'center', sm: 'space-between' }}
          alignItems={{ xs: 'center', sm: 'center' }}
          spacing={{ xs: 2, sm: 0 }}
          sx={{ height: '100%' }}
        >
          <Box sx={{ cursor: 'pointer' }} onClick={() => navigate('/')}>
            <HearingAgencyLogo color style={{ width: '175px' }} />
          </Box>
          <Box>
            <a href={`tel:${assignedNumber}`}>
              <Typography
                component='div'
                variant='h4'
                color='rgb(14, 40, 100)'
                sx={{
                  fontFamily: 'Lora, sans-serif',
                  fontWeight: 'bold',
                  filter: isLoading ? 'blur(5px)' : 'none'
                }}
              >
                {assignedNumber}
              </Typography>
            </a>
          </Box>
        </Stack>
      </Container>
    </Box>
  )
}

export default Header
