import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import PhoneIcon from '@mui/icons-material/Phone'
import SectionWrapper from '../../components/SectionWrapper'

import support from '../../assets/support.webp'

const OurServiceSection = ({
  assignedNumber,
  makeCall,
  isAssignedNumberLoading
}) => {
  return (
    <SectionWrapper sx={{ alignItems: 'stretch' }} breakpoint='899'>
      <>
        <Box
          display='flex'
          justifyContent={{ xs: 'center', lg: 'flex-start' }}
          alignItems='center'
          height='100%'
          sx={{
            '& > img': {
              maxWidth: { xs: '720px', md: '544px' },
              width: '100%',
              height: '100%',
              borderRadius: {
                xs: '0.25rem 6rem 0.25rem 0.25rem',
                md: '0.25rem 10rem 0.25rem 0.25rem'
              },
              boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.2)',
              objectFit: 'cover'
            }
          }}
        >
          <img src={support} alt='Support' />
        </Box>
      </>
      <>
        <Stack spacing={3}>
          <Typography
            variant='h4'
            color='rgb(14, 40, 100)'
            sx={{
              fontWeight: 700,
              fontFamily: 'Lora, sans-serif',
              fontSize: '2rem',
              lineHeight: '1.2'
            }}
          >
            Our discounts are big, but that's not why people like you buy from
            us
          </Typography>
          <Typography
            variant='body1'
            sx={{
              lineHeight: '1.25',
              fontSize: '1.25rem',
              letterSpacing: '0.00938em'
            }}
          >
            Exceptional service is our real secret sauce. Our commitment to you:
          </Typography>
          <Typography
            variant='body1'
            sx={{
              lineHeight: '1.25',
              fontSize: '1.25rem',
              letterSpacing: '0.00938em'
            }}
          >
            <strong>Risk free.</strong> Every hearing aid we sell comes with a
            risk-free trial period and a 45-day money back guarantee. Return or
            exchange it for any reason.
          </Typography>
          <Typography
            variant='body1'
            sx={{
              lineHeight: '1.25',
              fontSize: '1.25rem',
              letterSpacing: '0.00938em'
            }}
          >
            <strong>All inclusive.</strong> You’ll get an all-inclusive, upfront
            price. The price we quote you is the price you’ll pay. No hidden
            fees or surprises. Ever.
          </Typography>
          <Typography
            variant='body1'
            sx={{
              lineHeight: '1.25',
              fontSize: '1.25rem',
              letterSpacing: '0.00938em'
            }}
          >
            <strong>No sales pitch.</strong> You’ll never get a sales pitch from
            us. Just a friendly, licensed hearing expert who will help you find
            the right hearing aid.
          </Typography>
        </Stack>
        <Button
          variant='contained'
          onClick={makeCall}
          startIcon={<PhoneIcon style={{ fontSize: '1.5rem' }} />}
          sx={{
            marginTop: '48px',
            height: '3.5rem',
            borderRadius: '8px',
            textTransform: 'none'
          }}
        >
          <Typography
            variant='body1'
            sx={{
              fontWeight: 600,
              fontSize: '24px',
              letterSpacing: '0.00938em',
              lineHeight: '1.25',
              whiteSpace: 'noWrap',
              '@media screen and (max-width:1099px)': {
                fontSize: '19.2px'
              }
            }}
          >
            Call for exceptional service
          </Typography>
        </Button>
        <Typography
          variant='body1'
          sx={{ mt: '8px', color: 'rgb(14, 40, 100)' }}
        >
          <strong>We're open now!</strong> Call:{' '}
          <a href={`tel:${assignedNumber}`}>
            <span
              style={{
                textDecoration: 'underline',
                filter: isAssignedNumberLoading ? 'blur(5px)' : 'none'
              }}
            >
              {assignedNumber}
            </span>
          </a>
        </Typography>
      </>
    </SectionWrapper>
  )
}

export default OurServiceSection

// I want to add the support image to the inside of the grid element here. I would like for it to be centered inside the grid and only take up 75% of the total width. In addition I would like the image to appear to be slightly elevated with a faint shadow with round borders. I also want to make sure that the image also looks good and high res. It's ok to have clippings and such. The most important thing is maintain the right aspect ratio.
