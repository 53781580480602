import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import PhoneIcon from '@mui/icons-material/Phone'
import SectionWrapper from '../../components/SectionWrapper'

import earExam from '../../assets/ear-exam.webp'

const HomeVisitSection = ({
  assignedNumber,
  makeCall,
  isAssignedNumberLoading
}) => {
  return (
    <SectionWrapper sx={{ alignItems: 'stretch' }} breakpoint='899'>
      <>
        <Box
          display='flex'
          justifyContent={{ xs: 'center', lg: 'flex-start' }}
          alignItems='center'
          height='100%'
          sx={{
            '& > img': {
              maxWidth: { xs: '720px', md: '544px' },
              width: '100%',
              height: '100%',
              borderRadius: {
                xs: '6rem 0.25rem 0.25rem 0.25rem',
                md: '10rem 0.25rem 0.25rem 0.25rem '
              },
              boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.2)',
              objectFit: 'cover'
            }
          }}
        >
          <img src={earExam} alt='Support' />
        </Box>
      </>
      <>
        <Stack spacing={3}>
          <Typography
            variant='h4'
            color='rgb(14, 40, 100)'
            sx={{
              fontWeight: 700,
              fontFamily: 'Lora, sans-serif',
              fontSize: '2rem',
              lineHeight: '1.2'
            }}
          >
            We offer great service on your terms; we'll even come to you
          </Typography>
          <Typography
            variant='body1'
            sx={{
              lineHeight: '1.25',
              fontSize: '1.25rem',
              letterSpacing: '0.00938em'
            }}
          >
            Exceptional service is our real secret sauce. Our commitment to you:
          </Typography>

          <Typography
            variant='body1'
            sx={{
              lineHeight: '1.25',
              fontSize: '1.25rem',
              letterSpacing: '0.00938em'
            }}
          >
            <strong>House calls.</strong> There's no schlepping back and forth
            to a clinic, and no wasted time in the waiting room. We'll come to
            you, at a time that works for your schedule.
          </Typography>
          <Typography
            variant='body1'
            sx={{
              lineHeight: '1.25',
              fontSize: '1.25rem',
              letterSpacing: '0.00938em'
            }}
          >
            <strong>You're covered.</strong> We cover your area, including your
            neighborhood.
          </Typography>
          <Typography
            variant='body1'
            sx={{
              lineHeight: '1.25',
              fontSize: '1.25rem',
              letterSpacing: '0.00938em'
            }}
          >
            <strong>No extra fees.</strong> In-home fitting is included in our
            all-inclusive pricing. There are never any extra charges or hidden
            fees.
          </Typography>
        </Stack>
        <Button
          variant='contained'
          onClick={makeCall}
          startIcon={<PhoneIcon style={{ fontSize: '1.5rem' }} />}
          sx={{
            marginTop: '48px',
            height: '3.5rem',
            borderRadius: '8px',
            textTransform: 'none'
          }}
        >
          <Typography
            variant='body1'
            sx={{
              fontWeight: 600,
              fontSize: '24px',
              letterSpacing: '0.00938em',
              lineHeight: '1.25',
              whiteSpace: 'noWrap',
              '@media screen and (max-width:1099px)': {
                fontSize: '19.2px'
              }
            }}
          >
            Call to book appointment
          </Typography>
        </Button>
        <Typography
          variant='body1'
          sx={{ mt: '8px', color: 'rgb(14, 40, 100)' }}
        >
          <strong>We're open now!</strong> Call:{' '}
          <a href={`tel:${assignedNumber}`}>
            <span
              style={{
                textDecoration: 'underline',
                filter: isAssignedNumberLoading ? 'blur(5px)' : 'none'
              }}
            >
              {assignedNumber}
            </span>
          </a>
        </Typography>
      </>
    </SectionWrapper>
  )
}

export default HomeVisitSection
