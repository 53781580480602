import { createContext, useState, useEffect } from 'react'
import { httpsCallable } from 'firebase/functions'
import { doc, collection } from 'firebase/firestore'
import { db, functions } from './firebase'

export const UserTaggingContext = createContext()

const getIP = async () => {
  try {
    const response = await fetch('https://api.ipify.org?format=json')
    const json = await response.json()
    return json.ip
  } catch (error) {
    console.error('Unable to retrieve IP address.', error)
    return ''
  }
}

const getVisitorId = () => {
  let visitorId = null
  try {
    visitorId = localStorage.getItem('YES_HEARING_VISITOR_ID')
    if (!visitorId) {
      const docRef = doc(collection(db, 'visitors'))
      visitorId = docRef.id
      localStorage.setItem('YES_HEARING_VISITOR_ID', visitorId)
    }
  } catch (error) {
    console.warn('Access to localStorage is blocked.', error)
  }
  return visitorId
}

const getSourceId = () => {
  const urlParams = new URLSearchParams(window.location.search)
  let sourceId = urlParams.get('src')
  if (!sourceId) {
    sourceId = 'xgNXzdhfqHgmqAaWtvW3'
  }
  return sourceId
}

const getCampaign = () => {
  const urlParams = new URLSearchParams(window.location.search)
  let campaign = urlParams.get('cid')
  if (!campaign) {
    campaign = '1000186'
  }
  return campaign
}

const getLocation = () => {
  let location = {}
  try {
    const locationUrl = new URL(window.location)
    location = {
      search: locationUrl.search,
      host: locationUrl.host,
      hostname: locationUrl.hostname,
      href: locationUrl.href,
      origin: locationUrl.origin,
      pathname: locationUrl.pathname
    }
  } catch (error) {
    console.warn('Unable to retrieve location information.', error)
  }

  return location
}

const getParams = () => {
  try {
    const href = new URL(window.location.href)
    const params = Object.fromEntries(href.searchParams.entries())
    return params
  } catch (error) {
    console.warn('unable to retrieve search params', error)
  }
}

const createSession = async (
  visitorId,
  sourceId,
  phoneNumberData,
  campaign
) => {
  const docRef = doc(collection(db, 'sessions'))
  const sessionId = docRef.id

  const location = getLocation()
  const params = getParams()
  const referrer = document.referrer
  const sessionIp = await getIP()

  const session = {
    id: sessionId,
    sessionIP: sessionIp,
    sourceId: sourceId,
    campaign: campaign,
    visitor: visitorId,
    number: phoneNumberData.number,
    nationalNumber: phoneNumberData.nationalNumber,
    uriNumber: phoneNumberData.uriNumber,
    internationalNumber: phoneNumberData.internationalNumber,
    location: location,
    referrer: referrer,
    params: params
  }

  return session
}

const createSessionInDB = async (sessionData, setUserSessionData) => {
  const createWebSession = httpsCallable(functions, 'createWebSession')

  try {
    const result = await createWebSession(sessionData)

    if (result.data.status === 'success') {
      setUserSessionData({ ...sessionData })
      return true
    } else {
      console.error('Error creating session:', result.data.message)
      return false
    }
  } catch (error) {
    console.error('Error calling createSession function:', error)
    return false
  }
}

export const UserTaggingProvider = ({ children }) => {
  const [phoneNumberData, setPhoneNumberData] = useState(false)
  const [sessionData, setSessionData] = useState({})

  useEffect(() => {
    const fetchAndUpdateSessionPhoneNumber = async () => {
      const getDynamicPhoneNumberCallable = httpsCallable(
        functions,
        'getDynamicPhoneNumber'
      )

      try {
        const result = await getDynamicPhoneNumberCallable()

        if (result.data.number) {
          const data = result.data
          const number = data.number
          const nationalNumber = data.nationalNumber
          const uriNumber = data.uriNumber
          const internationalNumber = data.internationalNumber

          const formattedPhoneNumber = `${number.substring(
            0,
            3
          )}-${number.substring(3, 6)}-${number.substring(6)}`

          setPhoneNumberData({
            number: number,
            formattedPhoneNumber: formattedPhoneNumber,
            nationalNumber: nationalNumber,
            uriNumber: uriNumber,
            internationalNumber: internationalNumber
          })
        }
      } catch (error) {
        // Add default number here in event of error
        console.error('Error getting phone number:', error)
      }
    }

    fetchAndUpdateSessionPhoneNumber()
  }, [])

  useEffect(() => {
    const buildUserSession = async (phoneNumberData) => {
      try {
        if (phoneNumberData) {
          const visitorId = getVisitorId()
          const sourceId = getSourceId()
          const campaign = getCampaign()

          const sessionData = await createSession(
            visitorId,
            sourceId,
            phoneNumberData,
            campaign
          )

          await createSessionInDB(sessionData, setSessionData)
        }
      } catch (error) {
        console.error('Error creating session data:', error)
      }
    }

    buildUserSession(phoneNumberData)
  }, [phoneNumberData])

  return (
    <UserTaggingContext.Provider value={{ ...phoneNumberData, ...sessionData }}>
      {children}
    </UserTaggingContext.Provider>
  )
}
