import React from 'react'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import Container from '@mui/material/Container'

const BillOfSale = () => {
  const paragraphs = [
    {
      title: 'Alabama',
      body: 'The purchaser has been advised at the outset of his or her relationship with the hearing instrument apprentice, fitter, or dispenser that any examination(s) or representation(s) made by a licensed hearing instrument apprentice, fitter, or dispenser in connection with the fitting and selling of this hearing instrument(s) is not an examination, diagnosis, or prescription by a person licensed to practice medicine in this state and, therefore, must not be regarded as medical opinion or advice.'
    },
    {
      title: 'Colorado',
      body: 'The buyer has been advised at the outset of the buyer’s relationship with the dispenser that any examination or representation made by a dispenser in connection with the practice of dispensing, fitting, or dealing in hearing aids is not an examination, diagnosis, or prescription by a person licensed to practice medicine in this state and, therefore, must not be regarded as medical opinion or advice.'
    },
    {
      title: 'District of Columbia',
      body: 'This hearing aid will not restore normal hearing nor will it prevent further hearing loss. A return visit to a physician who is an ear specialist or audiologist after the purchase of this aid will help you in best adapting to it.'
    },
    {
      title: 'Florida',
      body: 'A hearing aid will not restore normal hearing, nor will it prevent further hearing loss. \n Chapter 484.0501(6), Florida Statutes, directs that each audiometric test conducted by a licensee or authorized trainee in the fitting and selling of hearing aids shall be made in a testing room that has been certified by the department, or by an agent approved by the department, not to exceed sound pressure levels at frequencies specified by law. \n However, an exception to this requirement shall be made in the case of a client who, after being provided written notice of the benefits and advantages of having the test conducted in a certified testing room, requests that the test be conducted in a place other than the licensee’s certified testing room. A signed copy of this waiver may be requested by your provider in which case you must sign this waiver, and a copy will be sent for your keeping.'
    },
    {
      title: 'Hawaii',
      body: 'The purchaser has been advised at the outset of the purchaser’s relationship with the hearing aid dealer and fitter that any examination or representation made by a hearing aid dealer and fitter in connection with the fitting and selling of this hearing aid is not an examination, diagnosis, or prescription by a person licensed to practice medicine in this State and shall not be considered as medical opinion or advice.'
    },
    {
      title: 'Iowa',
      body: 'The purchaser has been advised that any examination or representation made by a licensed hearing aid specialist in connection with the fitting or selection and selling of this hearing aid is not an examination, diagnosis, or prescription by a person licensed to practice medicine in this state and therefore, must not be regarded as medical opinion or advice.'
    },
    {
      title: 'Kentucky',
      body: 'The purchaser has been advised at the outset of his or her relationship with the specialist in hearing instruments that any examination(s) or representation(s) is not an examination, diagnosis, or prescription by a person licensed to practice medicine in this state and therefore shall not be regarded as medical opinion or advice.'
    },
    {
      title: 'Massachusetts',
      body: 'This hearing aid will not restore normal hearing nor will it prevent further hearing loss. The sale of a hearing aid is restricted to those individuals who have obtained a medical evaluation from a licensed physician or otolaryngologist. A fully informed adult whose religious or personal beliefs preclude consultation with a physician may waive the requirement of a medical evaluation. The exercise of such a waiver is not in your best health interest and its use is strongly discouraged. It is also required that a person under the age of eighteen years obtain an evaluation by an audiologist in addition to the medical evaluation before a hearing aid can be sold to such person.'
    },
    {
      title: 'Nebraska',
      body: 'The purchaser has been advised at the outset of his or her relationship with the hearing instrument specialist that any examination or representation made by a licensed hearing instrument specialist in connection with the fitting and selling of this hearing instrument is not an examination, diagnosis, or prescription by a person licensed to practice medicine in this state and therefore must not be regarded as medical opinion or advice.'
    },
    {
      title: 'New Hampshire',
      body: 'This hearing aid will not restore normal hearing nor will it prevent further hearing loss.'
    },
    {
      title: 'New Mexico',
      body: 'Buyer has been advised that licensee is not a licensed physician and that the examination and recommendation is made as a hearing aid dispenser or audiologist and not as a medical diagnosis or prescription.'
    },
    {
      title: 'New Jersey',
      body: 'The purchaser has been advised at the outset of his or her relationship with the hearing aid dispenser that any examination or representation made by a licensed hearing aid dispenser in connection with the practice of fitting and selling of this hearing aid, or hearing aids, is not an examination, diagnosis, or prescription by a person licensed to practice medicine in this State, or by certified audiologists and therefore must not be regarded as medical opinion.'
    },
    {
      title: 'New York',
      body: 'You are advised that with your entire relationship and dealings with Yes Hearing and any Yes Hearing audiologist, hearing instrument specialist or third party contractor that any examination or representation made by a dispenser of hearing aids in connection with dispensing hearing aids is not an examination, diagnosis, or prescription by a person licensed to practice medicine in New York, and must not therefore be regarded as medical opinion.'
    },
    {
      title: 'Oklahoma',
      body: 'Any examination or representation made by a licensed hearing aid dealer and fitter in connection with the fitting and selling of this hearing aid is not an examination, diagnosis, or prescription by a person licensed to practice medicine in this state and therefore must not be regarded as medical opinion or advice. Further, it is recommended that medical advice from a licensed physician should be obtained.'
    },
    {
      title: 'Ohio',
      body: 'The purchaser is advised that any examination, fitting, recommendation, or representation made by a licensed hearing aid dealer or fitter in connection with the sale of this hearing aid is not an examination, diagnosis, or prescription made by a person licensed to practice medicine in this state and therefore must not be regarded as medical opinion or advice.'
    },
    {
      title: 'Oregon',
      body: 'It is desirable that a person seeking help with a hearing problem (especially for the first time) consult an ear doctor and obtain a clinical hearing evaluation. Although hearing aids are often recommended for hearing problems, another form of treatment may be necessary.'
    },
    {
      title: 'Pennsylvania',
      body: 'The purchaser has been advised at the outset of his or her relationship with the hearing aid dealer that any examination or representation made by a registered hearing aid dealer and fitter in connection with the practice of fitting and selling of this hearing aid, is not an examination, diagnosis or prescription by a person licensed to practice medicine in this Commonwealth and therefore must not be regarded as medical opinion.'
    },
    {
      title: 'Rhode Island',
      body: 'The purchaser has been advised at the outset of his/her relationship with the hearing aid dealer that any examination(s) or representation(s) made by a licensed hearing aid dealer and fitter in connection with the fitting and selling of this hearing aid(s) is not an examination, diagnosis, or prescription by a person licensed to practice medicine in this state and therefore must not be regarded as medical opinion or advice.'
    },
    {
      title: 'Texas',
      body: 'The client has been advised that any examination or representation made by a licensed hearing instrument dispenser or apprentice permit holder or temporary training permit holder in connection with the fitting and selling of the hearing instrument(s) is not an examination, diagnosis or prescription by a person duly licensed and qualified as a physician or surgeon authorized to practice medicine in the State of Texas and, therefore, must not be regarded as medical opinion or advice.'
    },
    {
      title: 'Wyoming',
      body: 'The purchaser has been advised that any examination or representations made by a licensed hearing aid specialist in connection with either the fitting or selling of this hearing aid is not an examination, diagnosis or prescription by a person licensed to practice medicine in this state and shall not be regarded as medical opinion.'
    },
    {
      title: 'Regarding Returns and Adjustments:',
      body: 'IN ADDITION TO OTHER RIGHTS, THE BUYER HAS THE RIGHT TO CANCEL THIS PURCHASE FOR ANY REASON AT ANY TIME PRIOR TO TWELVE MIDNIGHT OF THE 45TH CALENDAR DAYS AFTER RECEIPT OF THE HEARING AID AND RETURN THE HEARING AID IN THE SAME CONDITION, ORDINARY WEAR AND TEAR EXCLUDED. YES HEARING WILL PROMPTLY REFUND ALL MONEYS PAID FOR THE PURCAHSE OF A HEARING AID IF IT HAS BEEN AND CANCELLED AND RETURNED WITHIN THIS 45 DAY PERIOD.\nYes Hearing will pay for return shipping, and will not retain any of the original purchase price.\nThe purchaser may contact Yes Hearing at (844) 470-0099 for the address and the office hours available for Yes Hearing audiologists for fitting or post-fitting adjustments and servicing of the hearing aids.'
    },
    {
      title: 'Yes Hearing Returns Policy',
      body: 'The hearing aid purchase may be returned within 45 days of the original purchase date. Yes Hearing charges a $100 restocking fee on all returned hearing aids. Yes Hearing will pay for the return shipping, but it is the customer’s responsibility to ship the item. The mailing date should be no longer than 45 days after the shipping date. To be eligible for return, the hearing aids must be in the same condition as when purchased, excluding ordinary wear and tear and usage. Once we have received the hearing aid, a member of the team will contact you in regards to the status of your refund.\nYou should expect to receive your refund within four weeks of Yes Hearing receiving the hearing aid(s). If you would like to return an item please email contact@yeshearing.com or call us on 844-470-0099'
    },
    {
      title: 'Extended Returns Due to Medical Opinion',
      body: 'If you receive a written opinion form a licensed physician who is qualified to diagnose diseases of the ear, after purchasing a hearing aid, which states that you have a hearing impairment for which a hearing aid provides no benefit, or have a medical condition which suggests that a hearing aid should not be used, and as a result of either condition have experienced no improvement in the quality of your hearing, we will issue a full refund of the purchase price for up to a year (365 days) after the date of the hearing aid purchase.'
    },
    {
      title: 'Warranty Policy',
      body: 'Yes Hearing provides two years of repair warranty for all hearing aids, provided by the manufacturer and covering manufacturing defects. Accessories are not covered under warranty. All product warranties are in effect from the date of purchase. Should repair of a hearing aid be necessary, we will endeavor to do this in a timely and efficient manner. To do this, please contact Yes Hearing by emailing contact@yeshearing.com or call 844-470-0099, and one of our staff members will talk you through the process. It will involve returning the product with a note explaining the issue in appropriate packaging. A product repaired under warranty maintains the original purchase warranty effective date.\nWarranty claims will not be valid if the device has been modified or damaged by the user, if the serial number has been altered, or if repairs have been made without the knowledge and  consent of Yes Hearing. Device loss and user damage are not covered under warranty.'
    }
  ]

  return (
    <Container maxWidth='lg' sx={{ padding: '5rem 0' }}>
      <Stack spacing={2}>
        <Typography variant='h5'>Bill of Sale</Typography>
        <Typography variant='body2'>
          All US Citizens must read and acknowledge the text from their state
          below regarding medical opinions, and the returns and adjustments
          policy of their state.
        </Typography>
        {paragraphs.map((paragraph, index) => (
          <>
            <Typography variant='h6' key={index}>
              {paragraph.title}
            </Typography>
            <Typography variant='body2'>{paragraph.body}</Typography>
          </>
        ))}
      </Stack>
    </Container>
  )
}

export default BillOfSale
