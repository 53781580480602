import { useContext, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { UserTaggingContext } from '../../utilities/tagging'

import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'

import IntroSection from './IntroSection'
import ProductDetailsSection from './ProductDetailsSection'
import OurServiceSection from './OurServiceSection'
import BrandChoiceSection from './BrandChoiceSection'
import HomeVisitSection from './HomeVisitSection'
import PriceQuoteSection from './PriceQuoteSection'
import ComparisonTableSection from './ComparisonTableSection'

import oticon from '../../assets/oticon.webp'
import phonak from '../../assets/phonak.webp'
import resound from '../../assets/resound.webp'
import signia from '../../assets/signia.webp'
import starkey from '../../assets/starkey.webp'

import halfCircle from '../../assets/half-circle.svg'
import halfDonut from '../../assets/half-donut.svg'
import quarterCircle from '../../assets/quarter-circle.svg'

const Brand = () => {
  const params = useParams()
  const brand = params.brandname
  const sessionData = useContext(UserTaggingContext)
  const [brandDetails, setBrandDetails] = useState({
    name: 'Phonak',
    product: 'Lumity',
    img: phonak
  })
  const [assignedNumber, setAssignedNumber] = useState('877-220-6389')
  const [isAssignedNumberLoading] = useState(false)

  useEffect(() => {
    if (sessionData.formattedPhoneNumber) {
      setAssignedNumber(sessionData.formattedPhoneNumber)
    }
  }, [sessionData])

  useEffect(() => {
    let data
    switch (brand) {
      case 'resound':
        data = {
          name: 'ReSound',
          product: 'One',
          img: resound
        }
        break
      case 'signia':
        data = {
          name: 'Signia',
          product: 'Charge and Go AX',
          img: signia
        }
        break
      case 'starkey':
        data = {
          name: 'Starkey',
          product: 'Genesis',
          img: starkey
        }
        break
      case 'oticon':
        data = {
          name: 'Oticon',
          product: 'Real',
          img: oticon
        }
        break
      default:
        data = {
          name: 'Phonak',
          product: 'Audeo Paradise',
          img: phonak
        }
        break
    }

    setBrandDetails(data)
  }, [brand])

  const makeCall = () => {
    window.location.href = `tel:${assignedNumber}`
  }

  return (
    <Box
      sx={{
        position: 'relative',
        paddingTop: {
          xs: '2.5rem',
          md: '64px'
        },
        '@media (max-width: 1099px)': {
          paddingTop: '2.5rem'
        }
      }}
    >
      <img
        src={halfCircle}
        alt='Half Circle'
        style={{
          position: 'absolute',
          top: 20,
          left: 0,
          transform: 'scaleX(-1)',
          width: '100vw',
          maxWidth: 400,
          zIndex: -1
        }}
      />
      <img
        src={halfDonut}
        alt='Half Donut Flipped'
        style={{
          position: 'absolute',
          top: 900,
          right: 0,
          width: '100vw',
          maxWidth: 500,
          zIndex: -1
        }}
      />
      <img
        src={halfDonut}
        alt='Half Donut'
        style={{
          position: 'absolute',
          top: 2000,
          left: 0,
          transform: 'scaleX(-1)',
          width: '100vw',
          maxWidth: 500,
          zIndex: -1
        }}
      />
      <img
        src={quarterCircle}
        alt='Quarter Circle'
        style={{
          position: 'absolute',
          bottom: 0,
          right: 0,
          width: '100vw',
          maxWidth: 450,
          zIndex: -1
        }}
      />
      <Container sx={{ paddingBottom: '4rem', maxWidth: '1320px !important' }}>
        <Stack direction='column' gap='64px'>
          <Box>
            <IntroSection
              brandDetails={brandDetails}
              assignedNumber={assignedNumber}
              isAssignedNumberLoading={isAssignedNumberLoading}
              makeCall={makeCall}
            />
          </Box>
          <Box>
            <ProductDetailsSection
              brandDetails={brandDetails}
              makeCall={makeCall}
              isAssignedNumberLoading={isAssignedNumberLoading}
            />
          </Box>
          <Box>
            <OurServiceSection
              assignedNumber={assignedNumber}
              makeCall={makeCall}
            />
          </Box>
          <Box>
            <BrandChoiceSection brandDetails={brandDetails} />
          </Box>
          <Box>
            <HomeVisitSection
              assignedNumber={assignedNumber}
              makeCall={makeCall}
              isAssignedNumberLoading={isAssignedNumberLoading}
            />
          </Box>
          <Box>
            <PriceQuoteSection />
          </Box>
          <Box>
            <ComparisonTableSection
              assignedNumber={assignedNumber}
              makeCall={makeCall}
            />
          </Box>
        </Stack>
      </Container>
    </Box>
  )
}

export default Brand
