import { useContext, useState } from 'react'
import { UserTaggingContext } from '../../../utilities/tagging'
import { functions } from '../../../utilities/firebase'

import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import LoadingButton from '@mui/lab/LoadingButton'

import { httpsCallable } from 'firebase/functions'
import { isValidNumber } from 'libphonenumber-js'
import * as yup from 'yup'
import { useFormik } from 'formik'

const schema = yup.object().shape({
  name: yup.string().required('Name is required'),
  number: yup
    .string()
    .matches(
      /^[2-9]\d{9}$/,
      'Invalid phone number. It should be 10 digits without any leading 1.'
    )
    .required('Phone number is required')
    .test(
      'is-valid-number',
      'Invalid phone number, please enter a valid US phone number',
      (value) => isValidNumber(value, 'US')
    ),
  email: yup.string().email('Invalid email').required('Email is required'),
  postalCode: yup
    .string()
    .required('Postal code is required')
    .matches(
      /^[0-9]{5}(-[0-9]{4})?$/,
      'Invalid postal code. Use 5 digits format.'
    )
})

const validateZipCode = async (postalCode) => {
  const getServicesByZipCallable = httpsCallable(
    functions,
    'getServicesByZipCallable'
  )
  return await getServicesByZipCallable({ zipCode: postalCode })
}

const Form = ({ submitted, setSubmitted }) => {
  const [loading, setLoading] = useState(false)
  const sessionData = useContext(UserTaggingContext)

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: '',
      number: '',
      email: '',
      postalCode: ''
    },
    validationSchema: schema,
    onSubmit: async (values, { setFieldError }) => {
      setLoading(true)
      const number = values.number
      const postalCode = values.postalCode

      let payLoad = {
        firstName: '',
        lastName: values.name,
        email: values.email,
        postalCode: values.postalCode,
        leadSource: 'Internet',
        leadSourceDetail: 'Free Quote',
        number: number,
        cid: sessionData.campaign,
        sessionId: sessionData.id
      }

      try {
        const zipCodeServiceResponse = await validateZipCode(postalCode)

        if (zipCodeServiceResponse.data.responseCode === 1) {
          setFieldError(
            'postalCode',
            'Invalid postal code, Please enter valid postal code'
          )
          return null
        }

        payLoad = {
          ...payLoad,
          eligibleForCare: zipCodeServiceResponse.data.eligibleForCare,
          availableServices: zipCodeServiceResponse.data.availableServices,
          availableProviders: zipCodeServiceResponse.data.availableProviders
        }

        const handleLeadSubmission = httpsCallable(
          functions,
          'handleLeadSubmission'
        )

        await handleLeadSubmission(payLoad)
        setSubmitted(true)
      } catch (error) {
        const code = error.code
        const message = error.message
        console.error({ code, message })
      } finally {
        setLoading(false)
      }
    }
  })

  if (submitted) {
    return (
      <Stack
        direction='column'
        justifyContent='center'
        spacing={2}
        sx={{ py: '2.5rem' }}
      >
        <Typography
          variant='h5'
          color='rgb(14, 40, 100)'
          textAlign='center'
          sx={{
            fontWeight: 700,
            fontFamily: 'Lora, sans-serif',
            fontSize: '2rem',
            lineHeight: '1.2'
          }}
        >
          Thank you for reaching out to us!
        </Typography>
        <Typography variant='h5' color='rgb(14, 40, 100)' textAlign='center'>
          Rest assured, we are here to assist you with your hearing needs. A
          member of our team will contact you shortly to help you find the best
          solutions from the comfort of your home.
        </Typography>
      </Stack>
    )
  }

  return (
    <Paper
      sx={{
        borderRadius: '16px',
        padding: '2rem',
        bgcolor: 'rgba(228, 143, 52, 0.25)',
        transition: 'height 0.3s ease-out'
      }}
    >
      <form
        id={'main'}
        name={'Main form at the bottom of Hearing Agency page'}
        onSubmit={formik.handleSubmit}
      >
        <Stack spacing={2}>
          <Stack spacing={1}>
            <Typography variant='body1' color='rgb(14, 40, 100)'>
              Full Name
            </Typography>
            <TextField
              name='name'
              placeholder='Enter your full name'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={
                formik.errors.name && formik.touched.name
                  ? formik.errors.name
                  : ''
              }
              sx={{
                '& .MuiInputBase-input': {
                  backgroundColor: '#FFF'
                },
                '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                  {
                    borderColor: 'rgb(14, 40, 100)'
                  }
              }}
            />
          </Stack>
          <Stack spacing={1}>
            <Typography variant='body1' color='rgb(14, 40, 100)'>
              Contact Number
            </Typography>
            <TextField
              name='number'
              placeholder='Enter your phone number'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.number && Boolean(formik.errors.number)}
              helperText={
                formik.errors.number && formik.touched.number
                  ? formik.errors.number
                  : ''
              }
              sx={{
                '& .MuiInputBase-input': {
                  backgroundColor: '#FFF'
                },
                '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                  {
                    borderColor: 'rgb(14, 40, 100)'
                  }
              }}
            />
          </Stack>
          <Stack spacing={1}>
            <Typography variant='body1' color='rgb(14, 40, 100)'>
              Email
            </Typography>
            <TextField
              name='email'
              placeholder='Enter your email address'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={
                formik.errors.email && formik.touched.email
                  ? formik.errors.email
                  : ''
              }
              sx={{
                '& .MuiInputBase-input': {
                  backgroundColor: '#FFF'
                },
                '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                  {
                    borderColor: 'rgb(14, 40, 100)'
                  }
              }}
            />
          </Stack>
          <Stack spacing={1}>
            <Typography variant='body1' color='rgb(14, 40, 100)'>
              Postal Code
            </Typography>
            <TextField
              name='postalCode'
              placeholder='Enter your postal code'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.postalCode && Boolean(formik.errors.postalCode)
              }
              helperText={
                formik.errors.postalCode && formik.touched.postalCode
                  ? formik.errors.postalCode
                  : ''
              }
              sx={{
                '& .MuiInputBase-input': {
                  backgroundColor: '#FFF'
                },
                '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                  {
                    borderColor: 'rgb(14, 40, 100)'
                  }
              }}
            />
          </Stack>
          <Typography
            sx={{
              fontSize: '0.75rem'
            }}
          >
            By clicking the button, I allow Yes Hearing and its affiliates and
            service providers to use my personal and contact information to
            provide automated marketing communications (including calls, emails,
            text messages, and prerecorded messages). I agree to Yes Hearing’s{' '}
            <a
              href='https://yeshearing.com/terms-conditions/#Privacy Policy'
              target='_blank'
              rel='noreferrer'
            >
              Privacy policy{' '}
            </a>
            and{' '}
            <a
              href='https://yeshearing.com/terms-conditions/'
              target='_blank'
              rel='noreferrer'
            >
              Terms of Service
            </a>
            , and I understand that I may withdraw my consent by unsubscribing
            from Yes Hearing’s marketing communications at any time. Message and
            data rates may apply, and message frequency will vary. Consent is
            not required to use Yes Hearing’s services.
          </Typography>
          <LoadingButton
            type='submit'
            onClick={formik.handleSubmit}
            loading={loading}
            sx={{
              height: '3.375rem',
              maxWidth: '25rem',
              borderRadius: '8px',
              padding: { md: '1rem 0 1rem 0', lg: '0.5rem 1rem 0.5rem 1rem' },
              textTransform: 'none'
            }}
          >
            <Typography sx={{ fontWeight: 'bold' }}>
              Submit My Information
            </Typography>
          </LoadingButton>
        </Stack>
      </form>
    </Paper>
  )
}

export default Form
