import { Children } from 'react'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'

const SectionWrapper = ({
  children,
  breakpoint = '1099',
  sx = {},
  direction = 'column'
}) => {
  const styledChildren = Children.map(children, (child) => (
    <Box sx={{ width: '100%', flexBasis: '50%', minHeight: 0 }}>{child}</Box>
  ))

  return (
    <Stack
      columnGap={{ xs: 4, lg: 8 }}
      rowGap={{ xs: 4, lg: 8 }}
      className='section'
      direction='row'
      justifyContent='space-between'
      sx={{
        ...sx,
        height: '100%',
        [`@media (max-width: ${breakpoint}px)`]: {
          flexDirection: direction
        }
      }}
    >
      {styledChildren}
    </Stack>
  )
}

export default SectionWrapper
