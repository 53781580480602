import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import PhoneIcon from '@mui/icons-material/Phone'
import Box from '@mui/material/Box'

import VideoComponent from './VideoComponent'
import SectionWrapper from '../../../components/SectionWrapper'

const IntroSection = ({
  brandDetails,
  assignedNumber,
  isAssignedNumberLoading,
  makeCall
}) => {
  return (
    <SectionWrapper>
      <Box>
        <Box
          sx={{
            position: 'relative',
            width: '100%',
            pb: '56%'
          }}
        >
          <VideoComponent />
        </Box>
      </Box>
      <>
        <Typography
          variant='h4'
          color='rgb(14, 40, 100)'
          sx={{
            fontWeight: 700,
            fontFamily: 'Lora, sans-serif',
            fontSize: '2rem',
            lineHeight: '1.2',
            mb: '24px'
          }}
        >
          {`Finally, a better way to buy ${brandDetails.name} hearing aids near me`}
        </Typography>
        <Typography
          variant='body1'
          sx={{ linHeight: '1.25', letterSpacing: '0.00938em' }}
        >
          <strong>Save 25-40% off</strong> and have your hearing aid fitted in
          the comfort of your own home. We've combined the great service of a
          local hearing expert with the discounting power of an internet
          retailer.
        </Typography>
        <Button
          variant='contained'
          onClick={makeCall}
          startIcon={<PhoneIcon style={{ fontSize: '1.5rem' }} />}
          sx={{
            marginTop: '48px',
            height: '3.5rem',
            borderRadius: '8px',
            textTransform: 'none'
          }}
        >
          <Typography
            variant='body1'
            sx={{
              fontWeight: 600,
              fontSize: '24px',
              letterSpacing: '0.00938em',
              lineHeight: '1.25',
              whiteSpace: 'noWrap',
              '@media screen and (max-width:1099px)': {
                fontSize: '19.2px'
              }
            }}
          >
            Call for 25-50% discount
          </Typography>
        </Button>
        <Typography
          variant='body1'
          sx={{ mt: '8px', color: 'rgb(14, 40, 100)' }}
        >
          <strong>We're open now!</strong> Call:{' '}
          <a href={`tel:${assignedNumber}`}>
            <span
              style={{
                textDecoration: 'underline',
                filter: isAssignedNumberLoading ? 'blur(5px)' : 'none'
              }}
            >
              {assignedNumber}
            </span>
          </a>
        </Typography>
      </>
    </SectionWrapper>
  )
}

export default IntroSection
