import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

const GenericNotFound = () => {
  const navigate = useNavigate()
  const [countdown, setCountdown] = useState(9)

  useEffect(() => {
    if (countdown > 0) {
      const timerId = setTimeout(() => {
        setCountdown(countdown - 1)
      }, 1000)
      return () => clearTimeout(timerId)
    } else {
      navigate('/')
    }
  }, [countdown, navigate])

  return (
    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
      <Box
        sx={{
          height: 'calc(100vh - 23rem)',
          maxWidth: '50%',
          textAlign: 'center',
          paddingTop: '15vh'
        }}
      >
        <Typography variant='subtitle1' color='rgb(14, 40, 100)'>
          ERROR
        </Typography>
        <Typography variant='h3' gutterBottom color='rgb(14, 40, 100)'>
          Oops!
        </Typography>
        <Typography variant='body1'>
          It seems the page you were looking for doesn't exist.
        </Typography>
        <Typography variant='body1'>
          Redirecting to home in{' '}
          <span style={{ fontWeight: 'bold', color: 'rgb(14, 40, 100)' }}>
            {countdown}
          </span>{' '}
          seconds...
        </Typography>
      </Box>
    </Box>
  )
}

export default GenericNotFound
