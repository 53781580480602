import { createTheme } from '@mui/material/styles'

const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgb(76, 175, 80)',
          color: '#ffffff',
          '&:hover': {
            backgroundColor: 'rgb(63, 149, 68)'
          },
          '&:active': {
            backgroundColor: 'rgb(50, 123, 57)'
          }
        }
      }
    }
  },
  typography: {
    body1: {
      fontSize: '1.25rem'
    }
  }
})

export default theme
