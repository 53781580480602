import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Routes, Route } from 'react-router-dom'
import { UserTaggingProvider } from './utilities/tagging'

import { Header, Footer } from './components'
import {
  Brand,
  Privacy,
  BillOfSale,
  PrivacyRequest,
  Terms,
  GenericNotFound
} from './pages'

const ScrollToTop = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual'
    }
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}

function App() {
  return (
    <>
      <ScrollToTop />
      <UserTaggingProvider>
        <Header />
        <Routes>
          <Route path='/' element={<Brand />} />
          <Route path='/brand/:brandname' element={<Brand />} />
          <Route path='/privacy' element={<Privacy />} />
          <Route path='/bill-of-sale' element={<BillOfSale />} />
          <Route path='/privacy-request' element={<PrivacyRequest />} />
          <Route path='/terms' element={<Terms />} />
          <Route path='*' element={<GenericNotFound />} />
        </Routes>
        <Footer />
      </UserTaggingProvider>
    </>
  )
}

export default App
