import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import PhoneIcon from '@mui/icons-material/Phone'

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@mui/material'

import CheckIcon from '@mui/icons-material/Check'
import PlaceIcon from '@mui/icons-material/Place'
import DiscountIcon from '@mui/icons-material/Discount'
import Paper from '@mui/material/Paper'
import SectionWrapper from '../../components/SectionWrapper'

const ComparisonTableSection = ({ assignedNumber, makeCall }) => {
  return (
    <SectionWrapper sx={{ alignItems: 'center' }} breakpoint='899'>
      <>
        <TableContainer
          component={Paper}
          elevation={6}
          sx={{
            padding: '.6rem 0rem',
            maxWidth: { xs: 'none', md: '34.25rem' },
            borderRadius: '8px',
            width: '100%'
          }}
        >
          <Table aria-label='comparison table'>
            <TableBody>
              <TableRow>
                <TableCell></TableCell>
                <TableCell align='center' sx={{ padding: '10px 0' }}>
                  <PlaceIcon
                    sx={{ color: 'rgb(228, 143, 52)' }}
                    style={{ fontSize: '35px' }}
                  />
                  <Typography
                    variant='body1'
                    sx={{ color: 'rgb(14, 40, 100)', fontSize: '16px' }}
                  >
                    Local Services
                  </Typography>
                </TableCell>
                <TableCell align='center' sx={{ padding: '10px 0' }}>
                  <DiscountIcon
                    sx={{ color: 'rgb(228, 143, 52)' }}
                    style={{ fontSize: '35px' }}
                  />
                  <Typography
                    variant='body1'
                    sx={{ color: 'rgb(14, 40, 100)', fontSize: '16px' }}
                  >
                    Discounted Pricing
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ paddingRight: '0' }}>
                  <Typography
                    variant='body2'
                    sx={{ color: 'rgb(14, 40, 100)', fontSize: '20px' }}
                  >
                    Hearing Clinics
                  </Typography>
                </TableCell>
                <TableCell align='center' padding='none'>
                  <CheckIcon color='success' style={{ fontSize: '35px' }} />
                </TableCell>
                <TableCell align='center' padding='none'></TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ paddingRight: '0' }}>
                  <Typography
                    variant='body2'
                    sx={{ color: 'rgb(14, 40, 100)', fontSize: '20px' }}
                  >
                    Internet Retailers
                  </Typography>
                </TableCell>
                <TableCell align='center' padding='none'></TableCell>
                <TableCell align='center' padding='none'>
                  <CheckIcon color='success' style={{ fontSize: '35px' }} />
                </TableCell>
              </TableRow>
              <TableRow sx={{ backgroundColor: 'rgb(251, 237, 224)' }}>
                <TableCell sx={{ paddingRight: '0' }}>
                  <Typography
                    variant='body1'
                    sx={{
                      color: 'rgb(228, 143, 52)',
                      fontWeight: 'bold',
                      fontSize: '26px',
                      fontFamily: 'Lora, sans-serif'
                    }}
                  >
                    Our Model
                  </Typography>
                </TableCell>
                <TableCell align='center' padding='none'>
                  <CheckIcon color='success' style={{ fontSize: '55px' }} />
                </TableCell>
                <TableCell align='center' padding='none'>
                  <CheckIcon color='success' style={{ fontSize: '55px' }} />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </>
      <>
        <Stack spacing={3}>
          <Typography
            variant='h4'
            color='rgb(14, 40, 100)'
            sx={{
              fontWeight: 700,
              fontFamily: 'Lora, sans-serif',
              fontSize: '2rem',
              lineHeight: '1.2',
              mb: '24px'
            }}
          >
            There's only one way we'd ever buy a hearing aid
          </Typography>
          <Stack spacing={3}>
            <Typography
              variant='body1'
              sx={{ linHeight: '1.25', letterSpacing: '0.00938em' }}
            >
              Hearing clinics offer good local service at high prices. Internet
              retailers offer discounted pricing, but little service. We've
              found a way to combine the best of both!
            </Typography>
            <Typography
              variant='body1'
              sx={{ linHeight: '1.25', letterSpacing: '0.00938em' }}
            >
              We offer discounted pricing and great local service. We've
              combined the best of both worlds, with quality service{' '}
              <strong>AND</strong> discounts of 25-40% off.
            </Typography>
          </Stack>
        </Stack>

        <Button
          variant='contained'
          onClick={makeCall}
          startIcon={<PhoneIcon style={{ fontSize: '1.5rem' }} />}
          sx={{
            marginTop: '48px',
            height: '3.5rem',
            borderRadius: '8px',
            textTransform: 'none'
          }}
        >
          <Typography
            variant='body1'
            sx={{
              fontWeight: 600,
              fontSize: '24px',
              letterSpacing: '0.00938em',
              lineHeight: '1.25',
              whiteSpace: 'noWrap',
              '@media screen and (max-width:1099px)': {
                fontSize: '19.2px'
              }
            }}
          >
            {`Call ${assignedNumber}`}
          </Typography>
        </Button>
      </>
    </SectionWrapper>
  )
}

export default ComparisonTableSection
