import React from 'react'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import Container from '@mui/material/Container'

const Privacy = () => {
  return (
    <Container maxWidth='lg' sx={{ padding: '5rem 0' }}>
      <Stack spacing={2}>
        <Typography variant='h5'>Privacy Policy</Typography>
        <Typography variant='body2'>
          Yes Hearing respects the right to privacy of all users of Yes
          Hearing’s website (the “Site”), and we are committed to protecting
          your privacy online. Please read the following to learn what
          information we collect from you, and how we use that information.
          Please email us if you have any questions about or Privacy Policy. Yes
          Hearing wants any information that you provide to us to remain as
          secure as possible. This statement explains our information practices.
          By using the Yes Hearing site, you acknowledge that you agree with
          this Privacy Policy and our website’s terms of use. If you do not
          agree with this Privacy Policy, please do not provide us with any
          Personal Information and please refrain from using our Site.
        </Typography>
        <Typography variant='h6'>Information Yes Hearing Collects</Typography>
        <Typography variant='body2'>
          Yes Hearing collect all the information that you provide to us, both
          online and offline. Examples of personal information we collect
          includes, but is not limited to, your name, address, phone number,
          email address, date of birth, credit card information, health
          information, including any photographs, images, or forms that you
          provide to Yes Hearing, and other personally identifiable information
          (collectively known as the “Personal Information”). If you contact us
          by email, we will keep a record of your contact information and
          correspondence. We reserve the right to use your email or any other
          personally identifiable information that you provide to use to respond
          to you and to send you marketing materials for our products and
          services. We will not share your information with outside parties who
          wish to market other products and services to you.
        </Typography>
        <Typography variant='body2'>
          Yes Hearing also receives and collects information from our third
          party affiliates, including but not limited to, hearing instrument
          specialists, hearing aid dispensers, hearing aid manufacturers, and
          audiologists, about your referral, treatment options and audiogram
          results.
        </Typography>
        <Typography variant='body2'>
          Yes Hearing also collects information from visitors and users to its
          site, including but not limited to IP addresses, browser types,
          location information and operating system used.
        </Typography>
        <Typography variant='h6'>Use of Information</Typography>
        <Typography variant='body2'>
          Yes Hearing may use your Personal Information to provide and to
          communicate with and about you to audiologists or hearing aid
          dispensers or any of our care specialists that treat or interact with
          you. We may transfer this Personal Information to our hearing aid
          manufacturers in order to process orders for hearing aids and
          administer payments, as well as to your health care providers, or
          other persons or entities working on your behalf. Yes Hearing may also
          transfer this Personal Information, as well as other information
          collected on its site, to outside contractors, auditors, consultants,
          or any other party engaged by Yes Hearing to assist in providing
          financial or operational activities and support on Yes Hearing’s
          behalf, including technical and processing services and analysis of
          our site’s performance.
        </Typography>
        <Typography variant='body2'>
          We may use your personal information to send communications to you in
          relation to the services provided by Yes hearing. Yes Hearing may also
          be in contact with you with regards to completing surveys or research
          questionnaires related to your opinion of current or future services
          that may be offered. However you have the right to opt out of such
          communications.
        </Typography>
        <Typography variant='body2'>
          Yes Hearing does not sell, trade, rent or share your identifying
          information (including your email address) with third parties unless
          we receive your permission, or we are required to do so by law, or we
          have a good faith belief that such disclosure is necessary to comply
          with a current judicial proceeding, a court order, a legal process
          served on Yes Hearing or to resolve any potential fraud or perceived
          irregularity in any audits of the accuracy of any documentation or
          information submitted to Yes Hearing by you or on your behalf, as
          deemed appropriate by Yes Hearing. Yes Hearing may be required or
          permitted by law to share your personal and protected health
          information under certain circumstances including reporting adverse
          reactions to certain items, product recalls, preventing disease and
          for health research purposes.
        </Typography>
        <Typography variant='h6'>Privacy Practices</Typography>
        <Typography variant='body2'>
          Yes Hearing is dedicated to maintaining the privacy of your
          individually identifiable health information. As part of our business,
          we may create records regarding the services we provide to you. We are
          required by law to maintain the confidentiality of health information
          that identifies you. We are also required by law to provide you with
          this notice of our legal duties and the privacy practices that we
          maintain in our practice concerning your Protected Health Information
          (PHI). Please find below more information on:
        </Typography>
        <Typography variant='body2'>
          <ul>
            <li>How we may use and disclose your PHI</li>
            <li>Your privacy rights in your PHI</li>
            <li>
              Our obligations concerning the use and disclosure of your PHI.
            </li>
          </ul>
        </Typography>
        <Typography variant='body2'>
          This notice and its terms apply to all records containing your PHI
          that are created or retained by Yes Hearing. We may use and disclose
          your PHI in the following ways:
        </Typography>
        <Typography variant='body2'>
          Treatment – Yes Hearing may use your PHI to identify the type of
          hearing aid which is best for you. We may ask for your test results
          (audiogram) for this purpose. People who work for or with Yes Hearing,
          including but not limited to audiologists and hearing care
          specialists, may use or disclose your PHI in order to assist others in
          your treatment. We may also disclose your PHI to others who may assist
          in your care, such as family members. We may also disclose your PHI to
          other health care providers related to your care.
        </Typography>
        <Typography variant='body2'>
          Payment – Yes Hearing may use and disclose your PHI for the purposes
          of billing and collecting payment for the services and items you
          receive from us. This may include your health insurer, as well as
          other providers. We may also use your PHI to bill you directly for
          services and items. We may disclose your PHI to other health care
          providers and entities to assist in their billing efforts.
        </Typography>
        <Typography variant='body2'>
          Operations – Yes Hearing may use and disclose your PHI to operate our
          business. Our business may use your PHI to evaluate the quality of
          care you receive from us, or to conduct cost-management and business
          planning activities for our practice. We may disclose your PHI to
          other health care providers and entities to assist in the health care
          operations.
        </Typography>
        <Typography variant='body2'>
          Treatment – Yes Hearing may use and disclose your PHI to inform you of
          potential hearing aid options or alternatives.
        </Typography>
        <Typography variant='body2'>
          Related benefits and services – Yes Hearing may use and disclose your
          PHI to inform you of health-related benefits or services that may be
          of interest to you.
        </Typography>
        <Typography variant='body2'>
          Legal disclosures – When required to do so by federal, state or local
          law, Yes Hearing will use and disclose your PHI.
        </Typography>
        <Typography variant='body2'>
          Public health risk – Yes Hearing may disclose your PHI to public
          health authorities that are authorized by law to collect information
          for the purpose of reporting problems with products, and notifying
          customers that the product they are using may have been recalled.
        </Typography>
        <Typography variant='body2'>
          Health oversight – Yes Hearing may disclose your PHI to a health
          oversight agency for activities authorized by law, for example
          investigations, inspections, audits, surveys, licensure and other
          actions, legal proceedings or actions, or other activities necessary
          for government to monitor government programs, or compliance with laws
          and the healthcare system in general.
        </Typography>
        <Typography variant='body2'>
          Law enforcement – We may release your PHI if asked to do so by a law
          enforcement official.
        </Typography>
        <Typography variant='h6'>Your Rights Regarding Your PHI</Typography>
        <Typography variant='body2'>
          You have certain rights regarding the PHI that we maintain about you,
          including:
        </Typography>
        <Typography variant='body2'>
          Communications – You have the right to request that Yes Hearing
          communicate with you about your health and related issues in a
          particular manner or at a certain location. For example, you may ask
          that we contract you at home, rather than at work. In order to request
          a type of confidential communication, you must make a written request
          to Yes Hearing clearly expressing a preference for the method of
          contact, or the location where you wish to be contacted.
        </Typography>
        <Typography variant='body2'>
          Restrictions – You have the right to request a restriction on our use
          or disclosure of your PHI for treatment, payment or health care
          operations. You also have the right that we restrict our disclose of
          your PHI to only certain individuals involved in your care or the
          payment for your care, such as family or friends. We are not required
          to agree to your request. However, if we do agree, we are bound by our
          agreement except with otherwise required by law, in an emergency, or
          when the information is necessary to treat you. Please call or email
          us to tell us what information you want restricted, to whom you want
          the restrictions to apply, and whether you are requesting a limit to
          our business’s use, disclosure or both.
        </Typography>
        <Typography variant='body2'>
          Copies and Inspections – You have the right to inspect and obtain a
          copy of the PHI that may be used to make decisions about you,
          including patient medical records and billing records. You must submit
          your request in writing.
        </Typography>
        <Typography variant='body2'>
          Amendment – You may ask us to amend your health information if you
          believe it is incorrect or incomplete, and may request an amendment as
          long as the information is kept by or for Yes Hearing. This request
          must be in writing, and we may deny your request if we consider it
          inaccurate or incomplete, not part of the PHI kept by or for the
          business, or not part of the PHI which you would be permitted to
          inspect or copy.
        </Typography>
        <Typography variant='body2'>
          Accounting of Disclosures – You have the right to request an
          “accounting of disclosures”, which is a list of certain non-routine
          disclosures our practice has made of your PHI for purposes not related
          to treatment, payment or operations. The routine business practices
          use of your PHI by Yes Hearing is not required to be documented.
        </Typography>
        <Typography variant='body2'>
          Paper copy – You have the right to receive a paper copy of our notice
          of privacy practices.
        </Typography>
        <Typography variant='body2'>
          Complaints – You have the right to file a complaint if you feel your
          privacy rights have been violated. You may file a complaint with the
          Secretary of the Department of Health and Human Services, or with our
          practice.
        </Typography>
        <Typography variant='body2'>
          Authorization – Yes Hearing will obtain your authorization for uses
          and disclosures that are not identified by this notice or permitted by
          applicable law. You may revoke any authorization you provide us
          regarding the use of your PHI.
        </Typography>
        <Typography variant='h6'>Cookies</Typography>
        <Typography variant='body2'>
          Yes Hearing uses cookies, which are small text files transferred from
          our website and stored on your device, and are used to provide a
          personalized service and to make our website and services better for
          you. Please check your browser settings as there you will likely have
          some control over whether you wish to store cookies.
        </Typography>
        <Typography variant='h6'>Google Analytics</Typography>
        <Typography variant='body2'>
          Google Analytics is a software service offered by Google that tracks
          and reports website traffic. Google uses the data collected to monitor
          the use of our site and track future visits. This is shared with other
          Google services, and may be used by Google to personalize ads on its
          own advertising network. There is a Google Analytics opt-out browser
          add on, which will stop information from being shared with Google
          Analytics about visit activity.
        </Typography>
        <Typography variant='h6'>Security</Typography>
        <Typography variant='body2'>
          We take the protection of customer data extremely seriously, including
          using third parties like Paypal to process payments in order to ensure
          a high degree of protection. However please be aware that no transfer
          of data over the internet is fully secure and we cannot ensure or
          guarantee the safe transfer of data.
        </Typography>
        <Typography variant='h6'>Children</Typography>
        <Typography variant='body2'>
          Yes Hearing does not knowingly or specifically collect personal
          information from anyone under the age of 18. No person under the age
          of 18 is authorized to submit any information on our site. If you are
          under the age of 18 we request that you do not use our site of submit
          any information. If we realise that we do have information collected
          from anyone under the age of 18, we shall remove that information from
          our system’s database.
        </Typography>
        <Typography variant='h6'>Links to Other Websites</Typography>
        <Typography variant='body2'>
          Our website contains links to other websites that have information we
          believe may be of interest. We do not endorse these sites and are not
          responsible for the content on these sites, or the privacy policies
          and practices of these sites. Each site may collect their own
          information, and this privacy policy does not apply to information
          collected on external sites, even they are linked to, through or from
          Yes Hearing.
        </Typography>
        <Typography variant='h6'>Accessing your information</Typography>
        <Typography variant='body2'>
          To request access to, to correct, or to delete any personal
          information that you have provided us, or to unsubscribe from any Yes
          Hearing communications please contact us at:
        </Typography>
        <Typography variant='body2'>Number: (844)-241-8475</Typography>
        <Typography variant='body2'>Email: contact@yeshearing.com</Typography>
        <Typography variant='h6'>Length of Time Holding Information</Typography>
        <Typography variant='body2'>
          Yes Hearing may hold your personal information for as long as we deem
          reasonable or as long as required by law.
        </Typography>
        <Typography variant='h6'>Amendments</Typography>
        <Typography variant='body2'>
          This privacy policy may be updated from time to time and without prior
          notice to you in order to reflect changes in our practices related to
          personal data. Yes Hearing recommend that you regularly visit this
          page to view the current privacy policy.
        </Typography>
        <Typography variant='h6'>Contact</Typography>
        <Typography variant='body2'>
          If you have any questions about this privacy policy please contact us
          at:
        </Typography>
        <Typography variant='body2'>Number:(844)-241-8475</Typography>
        <Typography variant='body2'>Email: contact@yeshearing.com</Typography>
      </Stack>
    </Container>
  )
}

export default Privacy
