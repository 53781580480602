import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import oticonLogo from '../../assets/oticon-logo.webp'
import phonakLogo from '../../assets/phonak-logo.webp'
import resoundLogo from '../../assets/resound-logo.webp'
import signiaLogo from '../../assets/signia-logo.webp'
import starkeyLogo from '../../assets/starkey-logo.webp'
import Container from '@mui/material/Container'
import SectionWrapper from '../../components/SectionWrapper'

const BrandChoiceSection = ({ brandDetails }) => {
  return (
    <SectionWrapper
      sx={{ alignItems: 'center', py: { xs: '0', md: '28px' } }}
      breakpoint='899'
      direction='column-reverse'
    >
      <>
        <Stack spacing={3}>
          <Typography
            variant='h4'
            color='rgb(14, 40, 100)'
            sx={{
              fontWeight: 700,
              fontFamily: 'Lora, sans-serif',
              fontSize: '2rem',
              lineHeight: '1.2'
            }}
          >
            {`We’ll make sure you get the right hearing aid, even if it's not a
            ${brandDetails.name}`}
          </Typography>
          <Typography
            variant='body1'
            sx={{ linHeight: '1.25', letterSpacing: '0.00938em' }}
          >
            Choosing the right hearing aid requires balancing lifestyle needs,
            style preferences, technical features, and budget.
          </Typography>
          <Typography
            variant='body1'
            sx={{ linHeight: '1.25', letterSpacing: '0.00938em' }}
          >
            We work with all major hearing aid brands to make sure you get the
            best possible choice for your needs.
          </Typography>
          <Typography
            variant='body1'
            sx={{ linHeight: '1.25', letterSpacing: '0.00938em' }}
          >
            Talk with a licensed hearing expert, who will carefully assess your
            needs and recommend the right hearing aid for you.
          </Typography>
          <Typography
            variant='body1'
            sx={{ fontWeight: 'bold', color: 'rgb(14,40,100)' }}
          >
            100% free. No obligations, no strings attached.
          </Typography>
        </Stack>
      </>
      <>
        <Container
          maxWidth='xs'
          sx={{
            width: '100%',
            '@media (max-width: 899px)': {
              maxWidth: '600px !important'
            }
          }}
        >
          <Grid
            container
            direction='column'
            display='flex'
            justifyContent='center'
            sx={{
              width: { xs: '90%', md: '90%' },
              marginBottom: { xs: '1rem' },
              '@media (max-width: 399px)': {
                rowGap: '24px !important'
              }
            }}
          >
            <Grid item xs={12} display='flex' justifyContent='flex-start'>
              <Box
                sx={{
                  width: '158px',
                  position: 'relative',
                  pb: '36%'
                }}
              >
                <img
                  src={phonakLogo}
                  alt='Phonak'
                  style={{
                    width: '100%',
                    position: 'absolute',
                    top: 0
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} display='flex' justifyContent='flex-end'>
              <Box
                sx={{
                  width: '158px',
                  position: 'relative',
                  pb: '37%'
                }}
              >
                <img
                  src={oticonLogo}
                  alt='Oticon'
                  style={{
                    width: '100%',
                    position: 'absolute',
                    top: 0
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} display='flex' justifyContent='flex-start'>
              <Box
                sx={{
                  width: '158px',
                  position: 'relative',
                  pb: '29%'
                }}
              >
                <img
                  src={signiaLogo}
                  alt='Signia'
                  style={{
                    position: 'absolute',
                    top: 0,
                    width: '100%'
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} display='flex' justifyContent='flex-end'>
              <Box
                sx={{
                  width: '158px',
                  position: 'relative',
                  pb: '58%'
                }}
              >
                <img
                  src={starkeyLogo}
                  alt='Starkey'
                  style={{
                    position: 'absolute',
                    top: 0,
                    width: '100%'
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} display='flex' justifyContent='flex-start'>
              <Box
                sx={{
                  width: '158px',
                  position: 'relative',
                  pb: '25%'
                }}
              >
                <img
                  src={resoundLogo}
                  alt='Resound'
                  style={{
                    position: 'absolute',
                    top: 0,
                    width: '100%'
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </>
    </SectionWrapper>
  )
}

export default BrandChoiceSection
